import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyAyry9zeE58BezbGr7kH6Mp4x7SJqpXTIU',
  authDomain: 'codex-peniel.firebaseapp.com',
  projectId: 'codex-peniel',
  storageBucket: 'codex-peniel.appspot.com',
  messagingSenderId: '573010355436',
  appId: '1:573010355436:web:ccda360c52dd7226a931c9',
  measurementId: 'G-MTZZ9TR2G0',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
